#join {
  text-align: center;
}

#join {
  position: absolute;
  margin: auto;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70%;
  width: 70%;
}

#session {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}
